import * as React from 'react';
import "./cotizacion.scss";
import { useState, useEffect } from "react";
import { dataAutos as dataAutosMTY } from './dataMTY';
import { dataAutos as dataAutosGDL } from './dataGDL';
import { useParams } from 'react-router-dom'
import { StyledButton2 } from '../../components/customButtons/StyledButton';
import styled from 'styled-components'
import {Leaf} from '@styled-icons/entypo/Leaf';
import Modal from '../../components/modal/Modal';
import ImageSlider from '../../components/slider/Slider';
import FormularioComponent from '../../components/customForm/FormularioComponent';
import FooterHome from '../../components/footer/FooterHome';

const LeafI = styled(Leaf)`
      color: #158000;      
      transform: translateY(-3px);
    `
export const CheckImg = () => {
  return (
    <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" focusable="false" className="" role="presentation" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
      <path d="M12 2C6.486 2 2 6.486 2 12s4.486 10 10 10 10-4.486 10-10S17.514 2 12 2zm0 18c-4.411 0-8-3.589-8-8s3.589-8 8-8 8 3.589 8 8-3.589 8-8 8z"></path>
      <path d="M9.999 13.587 7.7 11.292l-1.412 1.416 3.713 3.705 6.706-6.706-1.414-1.414z"></path>
    </svg>
  )
}
const Cotizacion = (props) => {
  const { id } = useParams();
  const [data, setData] = useState(dataAutosMTY.filter(data => Number(data.id) === Number(id)));
  const localSucursal = localStorage.getItem('sucursal');
  const [activeTab, setActiveTab] = useState(localSucursal ? localSucursal : "MTY"); // Añade un estado para el tab activo
  useEffect(() => {

    if (localSucursal) {
      SwitchTab(localSucursal);
    }

  }, [localSucursal]);
  const SwitchTab = (suc) => {
    setActiveTab(suc); // Actualiza el tab activo

    if (suc === "MTY") {
      //setData(dataAutosMTY);
      const newData = dataAutosMTY.filter(data => Number(data.id) === Number(id));
      setData(newData);
      localStorage.setItem('sucursal', suc);
    }
    if (suc === "GDL") {
      const newData = dataAutosGDL.filter(data => Number(data.id) === Number(id));
      setData(newData);
      localStorage.setItem('sucursal', suc);
    }
  }
  const images = data[0].imagenesSlider;
  const modelo = data[0].modelo;
  const marca = data[0].marca;
  const montoSemanal = data[0].montoSemanal;
  const descripcion = data[0].descripcion;
  const meses = data[0].meses;
  const electrico = data[0].electrico;
  const [isModalOpen, setModalOpen] = useState(false);

  const handleOpen = () => setModalOpen(true);
  const handleClose = () => setModalOpen(false);
  return (

    <>

      <div className="formulario">
        <div className="formulario-container left">
          <ImageSlider images={images} />
        </div>
        <div className="formulario-container right">
          <div className="formulario-container-info">
            <div className="info-top">
              <div className="info-top-txt">
                <p className="info-top-p1">{marca} {electrico && <span><LeafI size={20} /></span>}</p>
                <h2 className="info-top-h2">{modelo}</h2>
              </div>
              <p className="info-top-p2">{descripcion}</p>
              <ul className="info-top-ul">
                <li className="info-top-li">
                  <CheckImg />
                  Estándar  
                </li>
                <li className="info-top-li">
                  <CheckImg />
                  Nuevo 2023
                </li>
                <li className="info-top-li">
                  <CheckImg />
                  Con opción de compra *
                  
                </li>
                <li className="info-top-li">
                  <CheckImg />
                  {meses}
                </li>
              </ul>
            </div>
            <div className="info-bottom">
              <div className="info-bottom-txt">
                <p className="info-bottom-price">${montoSemanal}<span className="">-semanal</span></p>
                <p className="info-bottom-promo"> * Consulta términos y condiciones</p>
                <p className="info-bottom-promo"> * Precios sujetos a cambios sin previo aviso</p>
              </div>
              <div className="info-bottom-btn">
                <StyledButton2 onClick={handleOpen}>¡Lo quiero!</StyledButton2>
              </div>
            </div>
          </div>
        </div>

      </div>
      
      <Modal isOpen={isModalOpen} onClose={handleClose}>
        <FormularioComponent formTitle="Rellena el Formulario" data={{marca,modelo}}/>
      </Modal>
      <FooterHome/>
    </>
  );
};

export default Cotizacion;