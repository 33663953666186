import { useState, useEffect } from "react";
import axios from "axios";
import { toast } from "sonner";
import { globalMessage } from "../../helpers/globalMessage";
import { urlsAPIs } from "../../helpers/urlsAPIs";
const useLeadsLanding = () => {  
  const [loading, setLoading] = useState(null);
  const [done, setDone] = useState(null);
  const [open, setOpen] = useState(false);
  const [openModal, setOpenModal] = useState(null);
  const idMTY = 1;
  const idGDL = 2;
  const idOtros = 3;
  const idChatfuel = 1;
  const idCallbell = 2;
  const oPlazas = {
    [idMTY]: "MTY",
    [idGDL]: "GDL",
    [idOtros]: "OTROS",
  };
  const saveLeadFormOthers = (data, formType = '') => {
    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${urlsAPIs.saveLeadOthers}`,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer null",
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        if ([200, 201].includes(Number(response?.status))) {
          setDone(true);
        }
      })
      .catch((error) => {
        const status = error?.response?.status;
        if(formType === 'visitanos'){
          setOpenModal(false)
        }
        if (status === 400) {
          const sMessage = error?.response?.data?.message;
          const nCodigo = error?.response?.data?.code;
          if (nCodigo) {
            toast.error(globalMessage[sMessage]);
          } else {
            toast.error(sMessage);
          }
        } else {
          toast.error(globalMessage.errorServidor);
        }
        setOpen(false);
        setDone(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const saveLeadFormMTY = (data, formType = '') => {
    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${urlsAPIs.saveLead}/callbell`,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer null",
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        setDone(true);
      })
      .catch((error) => {        
        const status = error?.response?.status;
        if(formType === 'visitanos'){
          setOpenModal(false)
        }
        if (status === 400) {
          const sMessage = error?.response?.data?.message;
          const nCodigo = error?.response?.data?.code;
          if (nCodigo) {
            toast.error(globalMessage[sMessage]);
          } else {
            toast.error(sMessage);
          }
        } else {
          toast.error(globalMessage.errorServidor);
        }
        setOpen(false);
        setDone(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const saveLeadFormGDL = (data, formType = '') => {
    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${urlsAPIs.saveLead}/callbell`,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer null",
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        setDone(true);
      })
      .catch((error) => {
        const status = error?.response?.status;
        if(formType === 'visitanos'){
          setOpenModal(false)
        }
        if (status === 400) {
          const sMessage = error?.response?.data?.message;
          const nCodigo = error?.response?.data?.code;
          if (nCodigo) {
            toast.error(globalMessage[sMessage]); 
          } else {
            toast.error(sMessage);
          }
        } else {
          toast.error(globalMessage.errorServidor);
        }
        setOpen(false);
        setDone(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  // 04:03
  const checkLeadResponses = (oData) => {
    const estado = Number(oData?.estado);
    const viajes = Number(oData?.viajes);
    const apps = Number(oData?.apps);
    const aLeadStatusViajesRechazo = [
      1, // No tengo, soy nuevo
      2, // 1 a 499
      3, // 500 a 799
    ];
    const aLeadStatusViajesAceptado = [
      6, // 800 a 999
      4, // 1,000 a 1,499
      5, // 1,500 o más
    ];
    const aLeadStatusAppsRechazo = [
      2, // No
      4, // De Otra aplicación
    ];
    const aLeadStatusEstadoRechazo = [
      3, // Otros
    ];


  
    if (
      aLeadStatusViajesRechazo.includes(viajes) &&
      aLeadStatusAppsRechazo.includes(apps) &&
      aLeadStatusEstadoRechazo.includes(estado)
    ) {
      return "NO VIABLE";
    }
  
    if ((aLeadStatusAppsRechazo.includes(apps) || aLeadStatusEstadoRechazo.includes(estado)) || aLeadStatusViajesRechazo.includes(viajes)) {
      return "NCP";
    }
  
  
    // Respuesta por defecto
    return "NUEVO LEAD";
  };
  const oLocations = {
    1: "Nuevo León",
    2: "Guadalajara",
    3: "Otro",
  };
  const opcionesViajes = [
    { value: 1, label: "No tengo, soy nuevo" },
    { value: 2, label: "1 a 499" },
    { value: 3, label: "500 a 799" },
    { value: 6, label: "800 a 999" },
    { value: 4, label: "1,000 a 1,499" },
    { value: 5, label: "1,500 o más" },
  ];

  return {
    saveLeadFormOthers,
    saveLeadFormMTY,
    saveLeadFormGDL,
    loading,
    setLoading,
    done,
    setDone,
    oLocations,
    opcionesViajes,
    open,
    setOpen,
    oPlazas,
    idMTY,
    idGDL,
    idOtros,
    idChatfuel,
    idCallbell,
    checkLeadResponses,
    openModal,
    setOpenModal,
  };
};

export default useLeadsLanding;
