import React, { useEffect, useState } from 'react';

const CustomInput = ({
  name,
  label,
  setIsValidForm,
  callback,
  value,
  inputRef,
  isValidForm,
  submitAttempted,
  setSubmitAttempted,
  maxLength = ''
}) => {
  const [touched, setTouched] = useState(value.trim() !== '');

  useEffect(() => {
    if (isValidForm && value.length < 1) {
      setTouched(true);
    }
  }, [value, isValidForm]);

  const handleFocus = () => {
    setTouched(true);
  };

  const handleBlur = (e) => {
    const isValueEmpty = e.target.value.trim() === '';
    setIsValidForm(!isValueEmpty);
    setTouched(!isValueEmpty);
  };

  const handleChange = (e) => {
    const newValue = e.target.value;
    callback(newValue);

    const isValueEmpty = newValue.trim() === '';
    
    setTouched(!isValueEmpty);    
  };

  const inputClassName = `input-style ${(value.length < 1 && (submitAttempted)) ? 'invalid' : ''}`;

  return (
    <div className="form-item">
      <label className={`${touched || value !== '' ? 'label-a ' : ''}`} htmlFor={label}>
        {label}:
      </label>
      <input
        type="text"
        className={inputClassName}
        name={name}
        id={label}
        onFocus={handleFocus}
        onBlur={handleBlur}
        onChange={handleChange}
        value={value}
        ref={inputRef}
        maxLength={maxLength}
        required
      />
      {value.length < 1 && (touched || submitAttempted) && <span>Campo requerido</span>}
    </div>
  );
};

export default CustomInput;
