import React from 'react';

export default function DescripcionReferenciado() {
  const steps = [
    "Ingresa tus Datos de contacto",
    "Ingresa los datos de tu referenciado.",
    "Nuestros analistas revisaran a tu referenciado para ver que sea apto para adquirir una unidad y no se encuentre en nuestra base de datos.",
    "Nuestros vendedores le ayudan a adquirir su unidad.",
    "Recibe tu pago por medio de una tarjeta de bonos."
  ];

  return (
    <div className="cardDiv">
      {/* <h2 className="card-title">Programa de Referidos BESTA</h2> */}
      <p className="introDiv">
        Participa en el programa de Referidos BESTA, captura a un referido en el siguiente
        formulario si la persona no es un clientes o prospecto actual y adquiere una unidad con
        nosotros gana 3,000 pesos por unidades nuevas y 2,000 por unidades seminuevas.
      </p>
      <div className="steps-containerDiv">
        {steps.map((step, index) => (
          <div key={index} className="step">
            {/* <div className="step-number">{index + 1}</div> */}
            <div className="step-content">
              <strong>Paso {index + 1}:</strong> {step}
            </div>
          </div>
        ))}
      </div>
      <p className="reminder">
        *RECUERDA QUE DEBES SER UN CLIENTE ACTIVO PARA PODER RECIBIR TU PAGO POR REFERIDOS*
      </p>

      <style jsx>{`
        .cardDiv {
          width: 100%;
          max-width: 750px;
          margin: 0 auto;
          border-radius: 8px;
          font-family: Arial, sans-serif;
          padding: 20px;
          box-sizing: border-box;
        }
        .main-title {
          font-size: 28px;
          font-weight: bold;
          text-align: center;
          margin: 0 0 20px 0;
          color: #333;
        }
        .introDiv {
          font-size: 16px;
          margin-bottom: 20px;
          line-height: 1.5;
        }
        .steps-containerDiv {
          display: flex;
          flex-direction: column;
          gap: 15px;
        }
        .step {
          display: flex;
          align-items: flex-start;
        }
        .step-content {
          flex: 1;
        }
        .reminder {
          font-size: 14px;
          font-weight: bold;
          color: #ff0000;
          text-align: center;
          margin: 20px 0 0 0;
        }
        .c-right-contact {
          height: 90%;
        }
        .c-wrapper {
          flex-wrap: wrap;
        }

        @media (max-width: 768px) {
          .cardDiv {
            padding: 15px;
          }
          .main-title {
            font-size: 24px;
          }
          .introDiv {
            font-size: 14px;
          }
          .step-content {
            font-size: 14px;
          }
        }

        @media (max-width: 480px) {
          .card {
            padding: 10px;
          }
          .main-title {
            font-size: 20px;
          }
          .intro {
            font-size: 12px;
          }
          .step-content {
            font-size: 12px;
          }
          .reminder {
            font-size: 12px;
          }
        }
      `}</style>
    </div>
  );
}