import "./contact.scss";
import * as React from "react";
import { useRef, useState } from "react";
import { StyledButton } from "../../components/customButtons/StyledButton";
import MensajeContacto from "./MensajeContacto";
import FullScreenLoader from "../../components/loader/FullScreenLoader";
import { v4 as uuidv4 } from "uuid";
import SelectComponent from "../../components/viajes/SelectComponent";
import { opcionesApps } from "../../components/viajes/selectOptions";
import useLeadsLanding from "../../hooks/landing/useLeadsLanding";

import { Grid, useMediaQuery } from "@mui/material";
import DescripcionReferenciado from "./DescripcionReferenciado";

/**
 * Componente que maneja un formulario de contacto para que los usuarios envíen sus datos.
 * @autor ERL 2024-05-21 04:15 pm
 * @returns {JSX.Element}
 *
 */
const ContactReferenciado = () => {
  const form = useRef();

  const {
    saveLeadFormOthers,
    saveLeadFormMTY,
    saveLeadFormGDL,
    loading,
    setLoading,
    done,
    setDone,
    oLocations,
    opcionesViajes,
    oPlazas,
    idMTY,
    idGDL,
    idOtros,
    idChatfuel,
    idCallbell,
    checkLeadResponses,
  } = useLeadsLanding();

  /**
   * Maneja el envío de un formulario de registro de leads.
   * @author ERL 2024-09-16 03:40 pm
   * @param {Object} e - Evento de envío del formulario.
   * @returns {void}
   */
  const enviarCorreo = (e) => {
    e.preventDefault();
    setLoading(true);
    const uuid = uuidv4().slice(0, 8);
    // Enviar los datos a Google Sheets
    const fecha = new Date();
    const fechaFormateada = `${fecha.getDate().toString().padStart(2, "0")}/${(
      fecha.getMonth() + 1
    )
      .toString()
      .padStart(2, "0")}/${fecha.getFullYear()}`;
    const formData = new FormData(form.current);
    formData.append("uuid", uuid);
    formData.append("fecha", fechaFormateada);

    // Convertir FormData a un objeto JSON
    const formDataJson = {};
    formData.forEach((value, key) => {
      formDataJson[key] = value;
    });
    const {
      estado,
      nombre,
      viajes,
      telefono,
      codeReference,
      apps,
      apellidoP,
      apellidoM,
    } = formDataJson;
    const sTagLeadStatus = checkLeadResponses({
      estado,
      viajes,
      apps,
    });
    const labelViaje = opcionesViajes.filter(
      (v) => Number(v.value) === Number(viajes)
    )[0]?.label;
    formData.append("estado", oLocations[estado]);
    formData.append("viajes", labelViaje);

    if (Number(estado) === idMTY) {
      let data = {
        phoneNumber: telefono,
        lastNameF: apellidoP,
        source: "whatsapp",
        name: nombre,
        lastNameM: apellidoM,
        email: "",
        traficNumber: 13,
        uuid: "",
        tags: [oPlazas[Number(estado)], sTagLeadStatus, "Referenciados"],
        customFields: "[]",
        userId: 1,
        placeId: Number(estado),
        platformId: Number(estado) === idMTY ? idCallbell : idChatfuel,
        totalTrips: viajes,
        appsWork: "",
        clientId: 0,
        assignedUser: 1,
        codeReference: codeReference,
        questionAppWorks: apps,
      };

      saveLeadFormMTY(data);
    }

    if (Number(estado) === idGDL) {
      let data = {
        phoneNumber: telefono,
        lastNameF: apellidoP,
        source: "whatsapp",
        name: nombre,
        lastNameM: apellidoM,
        email: "",
        traficNumber: 13,
        uuid: "",
        tags: [oPlazas[Number(estado)], sTagLeadStatus, "Referenciados"],
        customFields: "[]",
        userId: 1,
        placeId: Number(estado),
        platformId: Number(estado) === idMTY ? idCallbell : idChatfuel,
        totalTrips: viajes,
        appsWork: "",
        clientId: 0,
        assignedUser: 1,
        codeReference: codeReference,
        questionAppWorks: apps,
      };
      saveLeadFormGDL(data);
    }
    if (Number(estado) === idOtros) {
      let data = {
        phoneNumber: telefono,
        lastNameF: apellidoP,
        source: "whatsapp",
        name: nombre,
        lastNameM: apellidoM,
        email: "",
        traficNumber: 13,
        uuid: "",
        tags: [oPlazas[Number(estado)], sTagLeadStatus, "Referenciados"],
        customFields: "[]",
        userId: 1,
        placeId: Number(estado),
        platformId: Number(estado) === idMTY ? idCallbell : idChatfuel,
        totalTrips: viajes,
        appsWork: "",
        clientId: 0,
        assignedUser: 1,
        codeReference: codeReference,
        questionAppWorks: apps,
      };
      saveLeadFormOthers(data);
    }
  };

  const responsive = useMediaQuery("(max-width: 768px)");
  return (
    <>
      {loading && <FullScreenLoader />}
      <div className="c-contact" id="contacto">
        {done ? (
          <MensajeContacto />
        ) : (
          <>
            <h1 className="c-title-contact">¿ERES REFERENCIADO?</h1>

            <div className="c-wrapper">
              <div className="c-left">
                <DescripcionReferenciado />
                <div className="c-info-item">
                  <img
                    src="assets/solicitud-enviada.png"
                    alt="Contacto besta"
                    className="c-imagen"
                  />
                </div>
              </div>
              {responsive && <DescripcionReferenciado />}
              <div className="c-right-contact">
                <p className="c-desc-contact">
                  <h3>¡Déjanos tus datos!</h3>
                  <p>Nosotros te contactamos</p>
                </p>
                <form ref={form} onSubmit={enviarCorreo}>
                  <input
                    className="input-contact"
                    type="text"
                    placeholder="Nombres"
                    name="nombre"
                    required
                  />
                  <input
                    className="input-contact"
                    type="text"
                    placeholder="Apellido paterno"
                    name="apellidoP"
                    required
                  />
                  <input
                    className="input-contact"
                    type="text"
                    placeholder="Apellido materno"
                    name="apellidoM"
                    required
                  />
                  <input
                    className="input-contact"
                    type="text"
                    placeholder="Teléfono"
                    name="telefono"
                    required
                    maxLength={10}
                  />
                  <input
                    className="input-contact"
                    type="hidden"
                    name="pagina"
                    value="inicio"
                    required
                  />
                  <input
                    className="input-contact"
                    type="hidden"
                    name="canal"
                    value="página web"
                  />

                  <label htmlFor="apps">
                    ¿Actualmente eres conductor de UBER y DIDI?
                  </label>
                  <SelectComponent options={opcionesApps} name="apps" />

                  <label htmlFor="viajes">
                    ¿Cuántos viajes llevas realizados?:
                  </label>
                  <SelectComponent options={opcionesViajes} name="viajes" />

                  <label htmlFor="estado">
                    ¿En qué parte de México vives?:
                  </label>
                  <select name="estado" className="select-css" required>
                    <option value="" disabled selected>
                      Selecciona una opción
                    </option>
                    <option value="1">Nuevo León</option>
                    <option value="2">Guadalajara</option>
                    <option value="3">Otro</option>
                  </select>
                  <br />

                  <>
                    <label for="codigoreferenciado">
                      Código de referenciado
                    </label>
                    <input
                      className="input-contact"
                      type="text"
                      placeholder="Código de referenciado"
                      name="codeReference"
                      required
                    />
                  </>

                  <textarea
                    rows="5"
                    placeholder="Mensaje"
                    name="mensaje"
                    className="textarea-contact"
                  />
                  <div className="button-contact">
                    <StyledButton disabled={loading}>Enviar</StyledButton>
                  </div>
                </form>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default ContactReferenciado;
