import React, { useState, useRef } from "react";
import CustomInput from "./CustomInput";
import "./customForm.scss";
import CustomTextarea from "./CustomTextarea";
import CustomSelect from "./CustomSelect";
import { StyledButton } from "../customButtons/StyledButton";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CircularLoader from "../loader/CircularLoader";
import { LANG } from "../../pages/contact/LANG";
import { Grid } from "@mui/material";
import { v4 as uuidv4 } from "uuid";
import { opcionesApps } from "../viajes/selectOptions";
import useLeadsLanding from "../../hooks/landing/useLeadsLanding";
/**
 * Componente que maneja un formulario de contacto para que los usuarios envíen sus datos.
 * @autor ERL 2024-08-16 03:12 pm
 * @returns {JSX.Element}
 */
function FormularioComponent({ formTitle, data }) {
  const sUnidad = `${data?.marca} ${data?.modelo}`;
  const form = useRef();
  const [nombreValid, setNombreValid] = useState(false);
  const [apellidoPValid, setApellidoPValid] = useState(false);
  const [apellidoMValid, setApellidoMValid] = useState(false);
  const [celularValid, setcelularValid] = useState(false);
  const [appTValid, setAppTValid] = useState(false);
  const [mensajeValid, setMensajeValid] = useState(false);
  const [nombre, setNombre] = useState("");
  const [apellidoP, setApellidoP] = useState("");
  const [apellidoM, setApellidoM] = useState("");
  const [celular, setCelular] = useState("");
  const [appT, setAppT] = useState("");
  const [mensaje, setMensaje] = useState("");
  const nombreRef = useRef(null);
  const apellidoPRef = useRef(null);
  const apellidoMRef = useRef(null);
  const celularRef = useRef(null);
  const appTRef = useRef(null);
  const mensajeRef = useRef(null);
  const [viajesValid, setViajesValid] = useState(false);
  const [viajes, setViajes] = useState("");
  const viajesRef = useRef(null);
  const [localidadValid, setLocalidadValid] = useState(false);
  const [localidad, setLocalidad] = useState("");
  const localidadRef = useRef(null);
  const [submitAttempted, setSubmitAttempted] = useState(false);
  const {
    saveLeadFormOthers,
    saveLeadFormMTY,
    saveLeadFormGDL,
    loading,
    setLoading,
    done,
    setDone,
    oLocations,
    opcionesViajes,
    oPlazas,
    idMTY,
    idGDL,
    idOtros,
    idChatfuel,
    idCallbell,
    checkLeadResponses
  } = useLeadsLanding();

  /**
   * Maneja el envío de un formulario de registro de leads.
   * @author ERL 2024-09-16 03:40 pm
   * @param {Object} e - Evento de envío del formulario.
   * @returns {void}
   */
  const registrarLead = (e) => {
    e.preventDefault();
    if (
      !nombreValid ||
      !apellidoPValid ||
      !apellidoMValid ||
      !celularValid ||
      !appTValid ||
      !viajesValid ||
      !localidadValid
    )
      return;
    setLoading(true);
    const uuid = uuidv4().slice(0, 8);
    const fecha = new Date();
    const fechaFormateada = `${fecha.getDate().toString().padStart(2, "0")}/${(
      fecha.getMonth() + 1
    )
      .toString()
      .padStart(2, "0")}/${fecha.getFullYear()}`;

    const formData = new FormData(form.current);
    formData.append("uuid", uuid);
    formData.append("fecha", fechaFormateada);
    const formDataJson = {};
    formData.forEach((value, key) => {
      formDataJson[key] = value;
    });
    const { estado, nombre, viajes, telefono, apps, apellidoP, apellidoM } =
      formDataJson;
    const sTagLeadStatus = checkLeadResponses({
      estado,
      viajes,
      apps,
    });
    const labelViaje = opcionesViajes.filter(
      (v) => Number(v.value) === Number(viajes)
    )[0]?.label;
    formData.append("estado", oLocations[estado]);
    formData.append("viajes", labelViaje);
    if (Number(estado) === idMTY) {
      let data = {
        phoneNumber: telefono,
        lastNameF: apellidoP,
        source: "whatsapp",
        name: nombre,
        lastNameM: apellidoM,
        email: "",
        traficNumber: 5,
        uuid: "",
        tags: [oPlazas[Number(estado)], sTagLeadStatus, "LANDING"],
        customFields: "[]",
        userId: 1,
        placeId: Number(estado),
        platformId: Number(estado) === idMTY ? idCallbell : idChatfuel,
        totalTrips: viajes,
        appsWork: "",
        clientId: 0,
        assignedUser: 1,
        unitData: sUnidad,
        questionAppWorks: apps,
      };
      saveLeadFormMTY(data);
    }
    if (Number(estado) === idGDL) {
      let data = {
        phoneNumber: telefono,
        lastNameF: apellidoP,
        source: "whatsapp",
        name: nombre,
        lastNameM: apellidoM,
        email: "",
        traficNumber: 5,
        uuid: "",
        tags: [oPlazas[Number(estado)], sTagLeadStatus, "LANDING"],
        customFields: "[]",
        userId: 1,
        placeId: Number(estado),
        platformId: Number(estado) === idMTY ? idCallbell : idChatfuel,
        totalTrips: viajes,
        appsWork: "",
        clientId: 0,
        assignedUser: 1,
        unitData: sUnidad,
        questionAppWorks: apps,
      };
      saveLeadFormGDL(data);
    }
    if (Number(estado) === idOtros) {
      let data = {
        phoneNumber: telefono,
        lastNameF: apellidoP,
        source: "whatsapp",
        name: nombre,
        lastNameM: apellidoM,
        email: "",
        traficNumber: 5,
        uuid: "",
        tags: [oPlazas[Number(estado)], sTagLeadStatus, "LANDING"],
        customFields: "[]",
        userId: 1,
        placeId: Number(estado),
        platformId: Number(estado) === idMTY ? idCallbell : idChatfuel,
        totalTrips: viajes,
        appsWork: "",
        clientId: 0,
        assignedUser: 1,
        unitData: sUnidad,
        questionAppWorks: apps,
      };
      saveLeadFormOthers(data);
    }
  };
  const options3 = [
    { value: 1, label: "Nuevo León" },
    { value: 2, label: "Guadalajara" },
    { value: 3, label: "Otro" },
  ];

  return (
    <>
      {!loading && !done ? (
        <div className="custom-form">
          <div className="form-title">
            <h2>{formTitle}</h2>
          </div>
          <form ref={form} onSubmit={registrarLead}>
            <div className="form-item">
              <CustomInput
                name="nombre"
                label="nombres"
                type="input"
                setIsValidForm={setNombreValid}
                isValidForm={nombreValid}
                value={nombre}
                callback={setNombre}
                inputRef={nombreRef}
                submitAttempted={submitAttempted}
                setSubmitAttempted={setSubmitAttempted}
              />
            </div>
            <div className="form-item">
              <CustomInput
                name="apellidoP"
                label="apellido paterno"
                type="input"
                setIsValidForm={setApellidoPValid}
                isValidForm={apellidoPValid}
                value={apellidoP}
                callback={setApellidoP}
                inputRef={apellidoPRef}
                submitAttempted={submitAttempted}
                setSubmitAttempted={setSubmitAttempted}
              />
            </div>
            <div className="form-item">
              <CustomInput
                name="apellidoM"
                label="apellido materno"
                type="input"
                setIsValidForm={setApellidoMValid}
                isValidForm={apellidoMValid}
                value={apellidoM}
                callback={setApellidoM}
                inputRef={apellidoMRef}
                submitAttempted={submitAttempted}
                setSubmitAttempted={setSubmitAttempted}
              />
            </div>
            <div className="form-item">
              <CustomInput
                name="telefono"
                label="celular con whatsapp"
                type="input"
                setIsValidForm={setcelularValid}
                isValidForm={celularValid}
                value={celular}
                callback={setCelular}
                inputRef={celularRef}
                submitAttempted={submitAttempted}
                setSubmitAttempted={setSubmitAttempted}
                maxLength={10}
              />
            </div>
            <div className="form-item">
              <CustomSelect
                name="apps"
                label="¿Actualmente eres conductor de UBER y DIDI?"
                setIsValidForm={setAppTValid}
                isValidForm={appTValid}
                value={appT}
                callback={setAppT}
                inputRef={appTRef}
                submitAttempted={submitAttempted}
                setSubmitAttempted={setSubmitAttempted}
                options={opcionesApps}
              />
            </div>
            <div className="form-item">
              <CustomSelect
                name="viajes"
                label="¿Cuántos viajes llevas realizados?"
                setIsValidForm={setViajesValid}
                isValidForm={viajesValid}
                value={viajes}
                callback={setViajes}
                inputRef={viajesRef}
                submitAttempted={submitAttempted}
                setSubmitAttempted={setSubmitAttempted}
                options={opcionesViajes}
              />
            </div>
            <div className="form-item">
              <CustomSelect
                name="estado"
                label="¿En que parte de México vives?"
                setIsValidForm={setLocalidadValid}
                isValidForm={localidadValid}
                value={localidad}
                callback={setLocalidad}
                inputRef={localidadRef}
                submitAttempted={submitAttempted}
                setSubmitAttempted={setSubmitAttempted}
                options={options3}
              />
            </div>
            <div className="form-item txt">
              <CustomTextarea
                name="mensaje"
                label="mensaje"
                setIsValidForm={setMensajeValid}
                isValidForm={mensajeValid}
                value={mensaje}
                callback={setMensaje}
                inputRef={mensajeRef}
                submitAttempted={submitAttempted}
                setSubmitAttempted={setSubmitAttempted}
              />
            </div>
            <div className="submit-form">
              <StyledButton type="submit">Enviar</StyledButton>
            </div>
          </form>
        </div>
      ) : (
        <Grid
          sx={{
            width: {
              xl: "620px",
              lg: "620px",
              md: "620px",
              sm: "420px",
            },
          }}
        >
          {loading ? (
            <CircularLoader />
          ) : done ? (
            <Grid className="correo-enviado-container">
              <Grid>
                <Grid>
                  <CheckCircleOutlineIcon
                    color="success"
                    className="succes-icon"
                  />
                </Grid>
                <Grid className="loader-msg-1">{LANG.labelListoEstas}</Grid>
              </Grid>
              <Grid className="loader-msg-2">{LANG.labelTeContactaremos}</Grid>
            </Grid>
          ) : (
            ""
          )}
        </Grid>
      )}
    </>
  );
}

export default FormularioComponent;
