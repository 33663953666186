
import { loadLink, PalencaLinkReact } from '@palenca/palenca-link';
import { useCallback, useContext } from 'react';
import '../../../../../styles/registro/prospectos/pasos/palenca.scss'
import axios from 'axios';
import { useState } from 'react';

import { toast } from 'sonner';
import { urlsAPIs } from '../../../../../helpers/urlsAPIs';
import { globalMessage } from '../../../../../helpers/globalMessage';
import { LANG } from './LANG';
import ModalConfirm from '../../../../modal/ModalConfirm';
import ReferenciasPendientes from '../referencias/ReferenciasPendientes';
import PalencaPlataformas from './PalencaPlataformas';
import { ProgressBarContext } from '../../../../../context/ProgressBarContext';
import { useEffect } from 'react';

const PUBLICKEY = process.env.REACT_APP_API_KEY_PALENCA_PUBLIC;
const WIDGETID = process.env.REACT_APP_WIDGETID_PALENCA;
const sApiKey = process.env.REACT_APP_API_KEY_PALENCA_PRIVATE;
const linkPromise = loadLink(`${PUBLICKEY}`, `${WIDGETID}`);
/**
 * Componente de Registro de Documentos
 * @author ERL 2023-06-28 10:51 AM
 * @param {function} nextStep - Función para ir al siguiente paso del registro
 * @param {object} headers - Headers para las llamadas API.
 * @param {int} nIdStatusPalenca - Id del status de palenca.
 * @param {int} nStatusPalenca - Status de palenca en los estados del progressbar.
 * @returns {JSX.Element} Componente de validacion de ganancias con palenca
 */

function Palenca({ nextStep, nextStepLocal, headers, nIdStatusPalenca, nStatusPalenca,
  nIdDidi: nIdDidiEstado,
  nIdUber: nIdUberEstado }) {

  const { jwt, logout, errorMetamap, setErrorMetamap } = useContext(ProgressBarContext);
  const [selectedPlatformsCheck, setSelectedPlatformsCheck] = useState([]);
  const [selectedPlatforms, setSelectedPlatforms] = useState(['uber', 'didi']);
  const [tieneGanancias, setTieneGanancias] = useState(null);
  const idUsuario = sessionStorage.getItem('u');
  
  const [open, setOpen] = useState(false);


  useEffect(() => {


    if (nIdDidiEstado !== 0 && nIdUberEstado !== 0 && nIdStatusPalenca === 0) {
      nextStep();
    }
  }, [nIdDidiEstado, nIdUberEstado, nIdStatusPalenca])


  /**
   * Verifica si ambas plataformas estan check y envia al usuario al siguiente paso de manera local o llamada a la API
   * @author ERL 2023-11-16 04:25 pm
   * @returns {void}
   */
  const multiplesPlataformas = async (selectedPlatformsCheck) => {
    if (selectedPlatformsCheck.length === 2) {

      try {
        const headers = { Authorization: "Bearer " + jwt };
        const id = sessionStorage.getItem('u');
        const response = await axios.get(`${urlsAPIs.urlProgresoEstado}/${id}`, { headers });
        const { data } = response;
        const { nIdDidi, nIdUber } = data;

        if (Number(nIdDidi) !== 0 && Number(nIdUber) !== 0) {
          nextStep()
        };
      } catch (error) {
        const { responseCode = 0, message = "" } = error?.response.data
        if (responseCode === '401' && message === 'jwt expired') {          
          logout()
        } else {
          toast.error(globalMessage.errorServidor);
          // Propagar el error para que pueda ser capturado por el bloque 'catch' del llamador
          throw error;
        }
      }
    }
  }

  /**
   * Método que inicializa datos asociados a las ganancias en la plataforma "Palenca" con valores predeterminados.
   * Luego intenta enviar estos datos al servidor a través de una solicitud POST.
   * En caso de éxito, abre un modal de confirmación y avanza al siguiente paso en el proceso.
   * En caso de error, muestra un mensaje de error.
   * @author ERL 2023-08-15 02:58 pm
   * @returns {void}
   */
  const noGananciasPalenca = useCallback(async () => {
    const dataToSave = {
      nViajes: 0,
      nSemanas: 0,
      nGanancias: 0,
      nPromedioGanancias: 0,
      bValidacion: 0,
      sNivel: "",
      sPlataforma: ""
    }
    try {
      const response = await axios.post(`${urlsAPIs.urlEnviarInfoPalenca}/${idUsuario}`, dataToSave, { headers: headers });
      const { nCodigo, sMensaje } = response.data;
      if (nCodigo === 0) {
        setOpen(true);
        await ModalConfirm.askConfirmation();
        setErrorMetamap(true)
        setOpen(false);
        nextStep();
      } else {
        toast.error(sMensaje)
      }
      //const sMensaje = "Ganancias verificadas con exito!";
    } catch (e) {
      toast.error(globalMessage.errorServidor)
    }
  }, [])


  /**
  * Verifica si cuantas plataformas esta seleccionadas y si ambas esta seleccionadas se ejecturara una funcion para ir al siguiente paso hasta que las 2 plataformas esten seleccionadas
  * en la segunda condicion se evalua que solo exista una plataforma seleccionada para llamar a la api para verificar en que paso se encuentra y moverlo al
  * @author ERL 2023-11-17 06:35 pm
  * @returns {void}
  */

  useEffect(() => {


    if (selectedPlatforms.length === 0 && selectedPlatformsCheck.length === 2) {
      multiplesPlataformas(selectedPlatformsCheck);
    } else {
      if (selectedPlatforms.length === 1 && selectedPlatformsCheck.length === 1) {
        nextStep();
      }
    }
    return () => {

    }
  }, [selectedPlatforms, selectedPlatformsCheck])

  const handleEvent = useCallback(async (event: string, data: object) => {

    if (event === "connection_error") {
      noGananciasPalenca()
    }
    if (data && data?.data?.account_id) {
      // Extrayendo el account_id del objeto data
      const { account_id } = data.data;
      // Creando la URL con el account_id en la ruta      
      const sUrlAccount = `${urlsAPIs.urlPalenca}/${account_id}`;
      const sUrlProfile = `${urlsAPIs.urlPalenca}/${account_id}/profile`;

      const sUrlEarnings = `${urlsAPIs.urlPalenca}/${account_id}/earnings/search`;

      // Creamos un objeto Date con la fecha end_date
      let dFechaActual = new Date();

      // Creamos la fecha inicial en el formato 'YYYY-MM-DD'
      let dFechaInicial = formatDate(dFechaActual);

      // Obtener el día de la semana (0 - domingo, 1 - lunes, ..., 6 - sábado)
      let diaSemana = dFechaActual.getDay();

      // Calcular la cantidad de días que debes restar para llegar al lunes
      let diasARestar = (diaSemana + 6) % 7;

      // Restar los días necesarios a la fecha actual
      dFechaActual.setDate(dFechaActual.getDate() - diasARestar);

      // Formatear la fecha en el formato deseado (YYYY-MM-DD)      
      let dFechaLunesFormateada = formatDate(dFechaActual);

      // Definimos la fecha 8 semanas atrás
      let dHace8Semanas = new Date(dFechaLunesFormateada);
      dHace8Semanas.setDate(dHace8Semanas.getDate() - 8 * 7);

      // Ajustamos la fecha a 8 semanas atrás para que sea domingo
      if (dHace8Semanas.getDay() !== 0) {
        let diasAtras = (dHace8Semanas.getDay() === 1) ? 1 : 7 - dHace8Semanas.getDay() + 1;
        dHace8Semanas.setDate(dHace8Semanas.getDate() - diasAtras);
      }

      // Ahora ajustamos la fecha actual para que sea domingo, el día antes del lunes de la semana actual
      if (dFechaActual.getDay() !== 0) {
        let diasAtras = (dFechaActual.getDay() === 1) ? 1 : 7 - dFechaActual.getDay() + 1;
        dFechaActual.setDate(dFechaActual.getDate() - diasAtras);
      }

      let dFechaFinal = formatDate(dHace8Semanas);

      const headersPalenca = {
        'x-api-key': sApiKey,
        'Content-Type': 'application/json'
      };
      const requestData = {
        start_date: dFechaInicial,
        end_date: dFechaFinal,
      };
      // Realizando la petición GET a la API

      let responseProfile
      try {
        responseProfile = await axios.get(sUrlProfile, { headers: headersPalenca });
      } catch (error) {
        noGananciasPalenca()
      }
      const respnseAccount = await axios.get(sUrlAccount, { headers: headersPalenca });

      const { level_name, lifetime_trips } = responseProfile.data.data.metrics_info;
      const responseEarnings = await axios.post(sUrlEarnings, requestData, { headers: headersPalenca });
      const { earnings } = responseEarnings.data.data;

      const { platform } = respnseAccount.data.data;

      if (earnings.length === 0) {
        noGananciasPalenca()
        setTieneGanancias(false);
      } else {
        // Ahora buscamos en los datos
        let earningsEnRango = earnings.filter(item => {
          let earningDate = new Date(item.earning_date);
          return earningDate >= dHace8Semanas && earningDate < dFechaActual;
        });

        let nTotalAmount = earningsEnRango.reduce((total, item) => total + item.amount, 0);
        let nTotalTrips = earningsEnRango.reduce((total, item) => total + item.count_trips, 0);
        // Obtén las semanas de las fechas de ganancias
        let semanas = earningsEnRango.map(item => {
          let date = new Date(item.earning_date);
          // Obtén el lunes de la semana
          let day = date.getDay();
          let diff = date.getDate() - day + (day === 0 ? -6 : 1); // Ajusta al lunes anterior si es domingo
          return new Date(date.setDate(diff));
        });

        // Crea un conjunto para eliminar las semanas duplicadas
        let uniqueSemanas = new Set(semanas.map(semana => semana.toISOString()));

        // Obtiene el número de semanas únicas
        let nSemanas = uniqueSemanas.size;

        // Asegurar de que el número de semanas no exceda 8
        nSemanas = Math.min(nSemanas, 8);

        // Calcula el promedio
        const promedio = parseFloat(nTotalAmount / nSemanas).toFixed(2);

        const dataToSave = {
          nViajes: nTotalTrips,
          nSemanas: nSemanas,
          nGanancias: nTotalAmount.toFixed(2),
          nPromedioGanancias: promedio,
          bValidacion: Number(promedio) >= 7000 ? 1 : 0,
          sNivel: level_name,
          sPlataforma: platform
        }
        try {
          const response = await axios.post(`${urlsAPIs.urlEnviarInfoPalenca}/${idUsuario}`, dataToSave, { headers: headers });
          const { nCodigo, sMensaje } = response.data;
          if (nCodigo === 0) {
            toast.success(sMensaje)
            setSelectedPlatforms(prevPlatforms => {
              const res = prevPlatforms.filter(p => !platform.includes(p));
              // Filtra y mantiene solo las plataformas que no están en 'platform'
              return res;
            });
          } else {

            toast.error(sMensaje, {
              duration: Infinity,
            });

          }
        } catch (error) {

          const { responseCode = 0, message = "" } = error?.response.data
          if (responseCode === '401' && message === 'jwt expired') {
            toast.error(globalMessage.labelSesionExpirada)
            logout()
          } else {
            toast.error(globalMessage.errorServidor);
          }
        }
      }
    }
  }, []);



  /**
   * Formateo de fechas a YYYY-MM-DD
   * @author ERL 28-06-2023 11:51 AM
   * @param {string} dateString - String con la fecha para formatear
   * @returns {string} Fecha formateada a YYYY-MM-DD
   */
  function formatDate(dateString) {
    let dDate = new Date(dateString);

    let nYear = dDate.getUTCFullYear();
    let nMonth = dDate.getUTCMonth() + 1;
    let nDay = dDate.getUTCDate();

    // Asegurándonos de que el mes y el día siempre tengan dos dígitos
    if (nMonth < 10) nMonth = '0' + nMonth;
    if (nDay < 10) nDay = '0' + nDay;

    return `${nYear}-${nMonth}-${nDay}`;
  }

  const SANDBOX_PALENCA = process.env.REACT_APP_SANDBOX_PALENCA;
  const options = {
    configuration: {
      hideConsent: true,
      country: 'mx',
      isSandbox: SANDBOX_PALENCA,
      platforms: selectedPlatforms
    },
    appearance: {
      primaryColor: '#483e6b',
    },
  };

  return (
    <>
      {(nIdStatusPalenca !== 0) && (nStatusPalenca > 0) ?

        <ReferenciasPendientes
          sMensaje={LANG.labelPendientes}
        />
        :
        <div className='palenca-container'>
          <>
            <ModalConfirm
              open={open}
              setOpen={setOpen}
              sTitulo={""}
              bRechazar={false}
            >
              {LANG.labelNoGanancias}
            </ModalConfirm>
            {tieneGanancias === false || errorMetamap ?
              ""
              :

              <>
                <PalencaPlataformas
                  selectedPlatformsCheck={selectedPlatformsCheck}
                  setSelectedPlatformsCheck={setSelectedPlatformsCheck}
                />
                <PalencaLinkReact
                  key={selectedPlatforms.join(",")}
                  link={linkPromise}
                  options={options}
                  onEvent={handleEvent}
                />
              </>
            }

          </>
        </div>}
    </>

  );
}

export default Palenca;
