import React, { useState } from 'react';
import { Button, Divider, Grid, Stack, Typography } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import './login.scss';
import { Google } from '@mui/icons-material';
import { IconButton, InputAdornment, useMediaQuery } from "@material-ui/core";


import { useNavigate } from 'react-router-dom';

import { Visibility, VisibilityOff } from '@material-ui/icons';
import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useLogin } from '../../hooks/usuariosApi/useLogin';
import { useGoogleAuth } from '../../hooks/googleApi/useGoogleLogin';
import { StyledButton2 } from '../../components/customButtons/StyledButton';
import { regex } from '../../helpers/regex';
import { CircularProgress, useTheme } from '@mui/material';
import { Link } from 'react-router-dom';

const defaultTheme = createTheme({
    palette: {
        primary: {
            main: '#483e6b',
        },
    },
});
/**
 * Componente de Login
 * @author ERL 2023-06-09 10:30 am 
 * @returns {JSX.Element} Componente de login
*/
export default function Login() {
    const jwt = sessionStorage.getItem('t');

    useEffect(() => {

        if (jwt) {
            if (jwt === '' || jwt.length === 0) {
                navigate("/login");
            }else{
                navigate("/");
            }
        } else {
            navigate("/login");
        }
    }, [jwt])
    const [showPassword, setShowPassword] = useState(false);

    const handleClickShowPassword = () => setShowPassword(!showPassword);
    const handleMouseDownPassword = (event) => event.preventDefault();

    // Definición del estado del componente    
    const [password, setPassword] = useState('');
    const [correo, setCorreo] = useState('');
    const [loading, setLoading] = useState(false);
    const [loadingRecuperar, setLoadingRecuperar] = useState(false);

    const { handleSubmit, setValue, formState: { errors }, control, setError, watch, clearErrors } = useForm({
        defaultValues: {
            sCorreo: '',
            sPassword: '',
        },
    });

    const correoForm = watch('sCorreo'); // Observa los cambios en el campo 'sCorreo'

    useEffect(() => {
        setCorreo(correoForm); // Almacena el valor en el estado cuando cambia
    }, [correoForm]);


    // Uso del hook personalizado useGoogleAuth
    const { login, googleToken, googleId } = useGoogleAuth(setCorreo, setLoading, setError, setValue);

    // Manejador del evento de envío del formulario
    const { logearUsuario, recuperarPassword } = useLogin(setLoading, setLoadingRecuperar, setError);

    // Obtención del tema actual
    const theme = useTheme();


    // Hook para la navegación
    const navigate = useNavigate();

    // Función para manejar nuevos usuarios
    const isNewUser = () => navigate("/registro")

    // Hooks para determinar el tamaño del dispositivo
    const mediumDevices = useMediaQuery(theme.breakpoints.down('md'));
    const smallDevices = useMediaQuery(theme.breakpoints.down('sm'));

    const [confirmacionPendiente, setConfirmacionPendiente] = useState(localStorage.getItem('confirmacionPendiente') ? Boolean(localStorage.getItem('confirmacionPendiente')) : false);
    useEffect(() => {
        if (confirmacionPendiente) {
            navigate("/registro");
        }
    }, [confirmacionPendiente]);
    return (
        <ThemeProvider theme={defaultTheme}>
            <Grid container component="main" sx={{ height: `${smallDevices ? "auto" : "100vh"}` }}>
                <CssBaseline />
                <Grid
                    item
                    xs={false}
                    sm={false}
                    md={7}
                    sx={{
                        backgroundImage: 'url(/assets/INICIO-CARRO-W.webp)',
                        backgroundRepeat: 'no-repeat',
                        backgroundColor: (t) =>
                            t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
                        backgroundSize: 'contain',
                        backgroundPosition: 'left',
                    }}
                />
                <Grid item xs={12} sm={12} md={5} component={Paper} elevation={6} square>
                    <Box
                        sx={{
                            my: 8,
                            mx: 4,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}
                    >
                        <Avatar sx={{ m: 1, bgcolor: 'primary.main' }}>
                            <LockOutlinedIcon />
                        </Avatar>
                        <Typography component="h1" variant="h5">
                            Inicia sesión
                        </Typography>
                        <Box component="form" noValidate onSubmit={handleSubmit(logearUsuario)} sx={{ mt: 1, mx:0 }}>
                            <Controller
                                name="sCorreo"
                                control={control}
                                defaultValue=""
                                rules={{
                                    required: "Información requerida",
                                    pattern: {
                                        value: regex.isValidCorreo,
                                        message: "Ingresa un correo valido",
                                    },
                                }}
                                render={({ field }) => (
                                    <TextField
                                        required
                                        fullWidth
                                        label="Correo"
                                        aria-invalid={errors.sCorreo ? "true" : "false"}
                                        id={`${errors.sCorreo?.type === 'required' ? "outlined-error-helper-text" : ""}`}
                                        error={Boolean(errors.sCorreo)}
                                        helperText={<><center>{errors.sCorreo?.message} </center></>}
                                        {...field}
                                        margin="normal"
                                        autoFocus
                                        onChange={(e) => {
                                            field.onChange(e); // mantén la funcionalidad onChange original                                              
                                            if (errors.sCorreo && regex.isValidCorreo.test(field.value)) {
                                                clearErrors("sCorreo");
                                            }
                                        }}

                                    />
                                )}
                            />
                            <Controller
                                name="sPassword"
                                control={control}
                                defaultValue=""
                                rules={{ required: "Información requerida" }}
                                render={({ field }) => (
                                    <TextField
                                        margin="normal"
                                        required
                                        fullWidth
                                        label="Password"
                                        type={showPassword ? 'text' : 'password'}
                                        {...field}
                                        aria-invalid={errors.sPassword ? "true" : "false"}
                                        id={`${errors.sPassword?.type === 'required' ? "outlined-error-helper-text" : ""}`}
                                        error={Boolean(errors.sPassword)}
                                        helperText={<><center>{errors.sPassword?.message}</center></>}
                                        InputProps={{ // este es el nuevo objeto de props
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        onClick={handleClickShowPassword}
                                                        onMouseDown={handleMouseDownPassword}
                                                    >
                                                        {showPassword ? <Visibility /> : <VisibilityOff />}
                                                    </IconButton>
                                                </InputAdornment>
                                            )
                                        }}

                                    />
                                )}
                            />
                            {/* <Grid item xs>
                                <Typography variant="p" onClick={() => recuperarPassword(correo)} className='link-style'>
                                    {loadingRecuperar ? <CircularProgress color="inherit" /> : "¿Olvidaste tu contraseña?"}
                                </Typography>
                            </Grid> */}
                            <Grid container sx={{ my: 2, ml: 0, mr: 0, p: 0, mx: 0 }} justifyContent="center" display={"flex"}>
                                <StyledButton2 width="100%">
                                    {loading ? <CircularProgress color="inherit" /> : "Inicia sesión"}
                                </StyledButton2>
                            </Grid>
                            {/* <Grid container fullWidth display={"flex"} justifyContent={"center"}>
                                <Grid>
                                    <Typography variant="p" onClick={isNewUser} className='link-style'>
                                        ¿No tienes una cuenta? Regístrate
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Stack spacing={1} sx={{ fontSize: '15px', mt: '10px', mb: '10px', width:"100%"}}>
                                <Divider sx={{ '--Divider-childPosition': `${50}%` }}>
                                    O
                                </Divider>
                            </Stack>
                            <Grid container justifyContent="center">
                                <Avatar sx={{ m: 1, bgcolor: '#ea4335' }} className='google-btn'>
                                    <Google onClick={login} />
                                </Avatar>
                            </Grid> */}


                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </ThemeProvider>
    );
}