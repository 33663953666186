export const globalMessage = {
    errorServidor: "Error al consultar la información, favor de contactar al administrador",
    errorComponente: "Error al consultar el componente, favor de contactar al administrador",
    errorCrearPDF: "Error al generar el PDF",
    errorArchivoNoSoportado: "Tipo de archivo no soportado.",
    errorInformacionRequerida: "Información requerida",
    errorSoloNumeros: "Formato incorrecto, solo se permiten numeros",
    errorProspecto: "Se ha rechazado al prospecto",
    labelAceptar:"Aceptar",
    labelRechazar:"Rechazar",
    labelCerrar:"Cerrar",
    labelCorreoInvalido:"Correo invalido",
    labelSi:"Si",
    labelNo:"No",
    labelSesionExpirada: 'Tu sesión ha expirado. Por favor, inicia sesión de nuevo.',
    codeReferenceNoValid: '¡El código de referenciado no es correcto! Verifique la información',
    cellphoneNoValid: '¡El número no es válido! Verifique la información',
    cellphoneExist: '¡El número ya se encuentra registrado!',
    emailExist: '¡El correo ya se encuentra registrado!',
    Callbell400: 'Error en la comunicación con el proveedor, por favor intente de nuevo.',
    Callbell500: 'Error en la comunicación con el proveedor, por favor intente de nuevo.',
    Callbell502: 'Error en la comunicación con el proveedor, por favor intente de nuevo.'
}