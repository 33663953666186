import "./contact.scss";
import * as React from "react";
import { useRef, useState } from "react";
import { StyledButton } from "../../components/customButtons/StyledButton";
import ModalSimple from "../../components/modal/ModalSimple";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { Button, Grid } from "@mui/material";
import CircularLoader from "../../components/loader/CircularLoader";
import { globalMessage } from "../../helpers/globalMessage";
import { LANG } from "./LANG";
import SelectComponent from "../../components/viajes/SelectComponent";
import { opcionesApps } from "../../components/viajes/selectOptions";
import { v4 as uuidv4 } from "uuid";
import { useNavigate } from "react-router-dom";

/**
 * Formulario de contacto
 * @author ERL 2023-09-22 11:36 am
 * @param {boolean} bg - Indica si se muestra el formulario de contacto con fondo
 * @returns {JSX.Element}
 */

const ContactForm = ({
  bg = true,
  saveLeadFormOthers,
  saveLeadFormMTY,
  saveLeadFormGDL,
  loading,
  setLoading,
  done,
  setDone,
  oLocations,
  opcionesViajes,
  open,
  setOpen,
  oPlazas,
  idMTY,
  idGDL,
  idOtros,
  idChatfuel,
  idCallbell,
  checkLeadResponses,
}) => {
  const form = useRef();

  /**
   * Maneja el envío de un formulario de registro de leads.
   * @author ERL 2024-09-16 03:40 pm
   * @param {Object} e - Evento de envío del formulario.
   * @returns {void}
   */
  const registrarLead = (e) => {
    e.preventDefault();
    setLoading(true);
    const uuid = uuidv4().slice(0, 8);
    const fecha = new Date();
    const fechaFormateada = `${fecha.getDate().toString().padStart(2, "0")}/${(
      fecha.getMonth() + 1
    )
      .toString()
      .padStart(2, "0")}/${fecha.getFullYear()}`;
    const formData = new FormData(form.current);
    formData.append("uuid", uuid);
    formData.append("fecha", fechaFormateada);
    const formDataJson = {};
    formData.forEach((value, key) => {
      formDataJson[key] = value;
    });
    const { estado, nombre, viajes, telefono, apps, apellidoP, apellidoM } =
      formDataJson;

    const sTagLeadStatus = checkLeadResponses({
      estado,
      viajes,
      apps,
    });

    const labelViaje = opcionesViajes.filter(
      (v) => Number(v.value) === Number(viajes)
    )[0]?.label;
    formData.append("estado", oLocations[estado]);
    formData.append("viajes", labelViaje);    
    setOpen(true)
    if (Number(estado) === idMTY) {
      let data = {
        phoneNumber: telefono,
        lastNameF: apellidoP,
        source: "whatsapp",
        name: nombre,
        lastNameM: apellidoM,
        email: "",
        traficNumber: 6,
        uuid: "",
        tags: [oPlazas[Number(estado)], sTagLeadStatus, "LANDING"],
        customFields: "[]",
        userId: 1,
        placeId: Number(estado),
        platformId: Number(estado) === idMTY ? idCallbell : idChatfuel,
        totalTrips: viajes,
        appsWork: "",
        clientId: 0,
        assignedUser: 1,
        questionAppWorks: apps,
      };

      saveLeadFormMTY(data, 'visitanos');
    }

    if (Number(estado) === idGDL) {
      let data = {
        phoneNumber: telefono,
        lastNameF: apellidoP,
        source: "whatsapp",
        name: nombre,
        lastNameM: apellidoM,
        email: "",
        traficNumber: 6,
        uuid: "",
        tags: [oPlazas[Number(estado)], sTagLeadStatus, "LANDING"],
        customFields: "[]",
        userId: 1,
        placeId: Number(estado),
        platformId: Number(estado) === idMTY ? idCallbell : idChatfuel,
        totalTrips: viajes,
        appsWork: "",
        clientId: 0,
        assignedUser: 1,
        questionAppWorks: apps,
      };
      saveLeadFormGDL(data, 'visitanos');
    }
    if (Number(estado) === idOtros) {
      let data = {
        phoneNumber: telefono,
        lastNameF: apellidoP,
        source: "whatsapp",
        name: nombre,
        lastNameM: apellidoM,
        email: "",
        traficNumber: 6,
        uuid: "",
        tags: [oPlazas[Number(estado)], sTagLeadStatus, "LANDING"],
        customFields: "[]",
        userId: 1,
        placeId: Number(estado),
        platformId: Number(estado) === idMTY ? idCallbell : idChatfuel,
        totalTrips: viajes,
        appsWork: "",
        clientId: 0,
        assignedUser: 1,
        questionAppWorks: apps,
      };
      saveLeadFormOthers(data, 'visitanos');
    }
  };
  const navigate = useNavigate();
  return (
    <>
      <ModalSimple open={open} setOpen={setOpen} sTitulo={""} bRechazar={false}>
        <Grid>
          {loading ? (
            <CircularLoader />
          ) : (
            <Grid className="correo-enviado-container">
              <Grid>
                <Grid>
                  <CheckCircleOutlineIcon
                    color="success"
                    className="succes-icon"
                  />
                </Grid>
                <Grid className="loader-msg-1">{LANG.labelListoEstas}</Grid>
              </Grid>
              <Grid className="loader-msg-2">{LANG.labelTeContactaremos}</Grid>
              <Grid className="loader-btn">
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: "#483e6b",
                    "&:hover": { backgroundColor: "#483e6b" },
                  }}
                  onClick={() => navigate("/")}
                >
                  {globalMessage.labelCerrar}
                </Button>
              </Grid>
            </Grid>
          )}
        </Grid>
      </ModalSimple>
      <div className="c-contact" id="contacto">
        <div className="c-bg"></div>
        <div className="c-wrapper">
          <div className={`c-right-contact ${!bg && "contact-nobg"}`}>
            <p className="c-desc-contact">
              <h3>¡Déjanos tus datos!</h3>
              <p>Nosotros te contactamos</p>
            </p>
            <form ref={form} onSubmit={registrarLead}>
              <input
                className="input-contact"
                type="text"
                placeholder="Nombres"
                name="nombre"
                required
              />
              <input
                className="input-contact"
                type="text"
                placeholder="Apellido paterno"
                name="apellidoP"
                required
              />
              <input
                className="input-contact"
                type="text"
                placeholder="Apellido materno"
                name="apellidoM"
                required
              />
              <input
                className="input-contact"
                type="text"
                placeholder="Celular con whatsapp"
                name="telefono"
                required
                maxLength={10}
              />
              <input
                className="input-contact"
                type="hidden"
                placeholder="pagina"
                name="pagina"
                value="inicio"
                required
              />
              <label htmlFor="">
                ¿Actualmente eres conductor de UBER y DIDI?{" "}
              </label>
              <SelectComponent options={opcionesApps} name="apps" />

              <label htmlFor="">¿Cuántos viajes llevas realizados?: </label>
              <SelectComponent options={opcionesViajes} name="viajes" />
              <label htmlFor="">¿En que parte de México vives?: </label>
              <select name="estado" className="select-css" required>
                <option value="" selected="selected">
                  Selecciona una opción{" "}
                </option>
                <option value="1">Nuevo León</option>
                <option value="2"> Guadalajara</option>
                <option value="3">Otro</option>
              </select>
              <textarea
                rows="5"
                placeholder="Mensaje"
                name="mensaje"
                className="textarea-contact"
              />
              <div className="button-contact">
                <StyledButton>Enviar</StyledButton>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactForm;
