import { useState, useEffect } from 'react';
import axios from "axios";
import { urlsAPIs } from '../../../helpers/urlsAPIs';
const useLeads = ({nIdProspecto}) => {

  const [aLeadData, setLeadData] = useState(0);
  const getProspectoLead = (nIdProspecto) =>{     
      let config = {
        method: 'get',
        maxBodyLength: Infinity,        
        url: `${urlsAPIs.updateLeadStatus}lead?prospectus=${nIdProspecto}`,
        headers: { 
          'Accept': 'application/json', 
          'Authorization': 'Bearer null'
        }
      };
      
      axios.request(config)
      .then((response) => {        
        if(Number(response?.status) === 200){
          setLeadData(response.data.data[0])
        }
      })
      .catch((error) => {        
      });
  }
  useEffect(() => {
    if(nIdProspecto > 0){
      getProspectoLead(nIdProspecto)
    }
  }, [nIdProspecto])
  
  return {aLeadData};
};

export default useLeads;