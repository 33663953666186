import { Grid } from '@mui/material'
import React, { useEffect, useRef } from 'react'
import './footerHome.scss';
import { Link } from 'react-router-dom';
import { useContext } from 'react';
import { ProgressBarContext } from '../../context/ProgressBarContext';
import CollapsibleList from '../collapsible/CollapsibleList';
import { LANG } from './LANG';
import { HashLink } from 'react-router-hash-link';
/**
 * Pie de página (footer) del inicio.
 * @author ERL 2023-09-18 03:41 pm
 * @returns {JSX.Element} 
 */

const FooterHome = () => {
    const footerRef = useRef(null);
    const { setMostrarIcoWhats } = useContext(ProgressBarContext)

    /**
     * Encargado de observar cuando el elemento referenciado por 'footerRef' está 
     * intersectando con el viewport. Esta intersección es utilizada para controlar la visibilidad del ícono de 
     * WhatsApp. Cuando el elemento 'footerRef' entra en el viewport, se oculta el ícono de WhatsApp. El efecto 
     * utiliza un IntersectionObserver para lograr esta funcionalidad.
     * 
     * Al desmontarse el componente, el observer se desconecta para evitar fugas de memoria.
     * 
     * @author ERL 2023-08-17 01:04 pm
     */

    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                const [entry] = entries;
                if (entry.isIntersecting) {
                    setMostrarIcoWhats(false);
                } else {
                    setMostrarIcoWhats(true);
                }
            },
            {
                threshold: 0.1
            }
        );

        if (footerRef.current) {
            observer.observe(footerRef.current);
        }
        return () => {
            observer.disconnect();
        };
    }, []);


    const oEnlaces = {
        "enlaces": [
            {
                sNombre: "Beneficios",
                sURL: "#beneficios",
                bHasLink:true,
            },
            {
                sNombre: "Requisitos",
                sURL: "#requisitos",
                bHasLink:true,
            },
            {
                sNombre: "Contacto",
                sURL: "visitanos",
                bHasLink:false,
            },
            {
                sNombre: "Referenciado",
                sURL: "referenciado",
                bHasLink:false,
            },
        ],
        "social": [
            {
                sNombre: "Alianzas comerciales",
                sURL: "/alianza-uberbesta",
                bExterno: 0
            },
            {
                sNombre: "Facebook",
                sURL: "https://www.facebook.com/bestamx",
                bExterno: 1
            },
            {
                sNombre: "Instagram",
                sURL: "https://www.instagram.com/bestamx/",
                bExterno: 1
            },
        ],
        "legal": [
            {
                sNombre: "Preguntas frecuentes",
                sURL: "/preguntas-frecuentes"
            },
            {
                sNombre: "Aviso de privacidad",
                sURL: "/privacidad"
            },
        ],
    }
    const { enlaces, social, legal } = oEnlaces;
    const logo = "/assets/LogoBESTA.png"
    return (
        <Grid className='footer-main' ref={footerRef}>
            <Grid className='footer-links'>
                <Grid className='footer-logo' >
                    <img src={logo} alt="BESTA" />
                </Grid>
                <div className='footer-web'>
                    <div className='footer-link enlaces'>
                        <h3>{LANG.labelEnlaces}</h3>
                        {enlaces.map(e => e.bHasLink ? <HashLink smooth to={{ pathname: "/", hash: e.sURL }}>{e.sNombre}</HashLink> : <Link to={`/${e.sURL}`}>{e.sNombre}</Link>)}
                    </div>
                    <div className='footer-link social'>
                        <h3>{LANG.labelSocial}</h3>
                        {social.map(s => Boolean(s.bExterno) ? <a href={s.sURL} target='_blank' rel="noreferrer">{s.sNombre}</a> : <Link to={s.sURL}>{s.sNombre}</Link>)}
                    </div>
                    <div className='footer-link legal'>
                        <h3>{LANG.labelLegal}</h3>
                        {legal.map(l => <Link to={l.sURL}>{l.sNombre}</Link>)}
                    </div>

                </div>
                <div className='footer-mobile'>
                    <CollapsibleList sTitulo={LANG.labelEnlaces}>
                        <div className='footer-link enlaces'>
                            {enlaces.map(e => e.bHasLink ? <HashLink smooth to={{ pathname: "/", hash: e.sURL }}>{e.sNombre}</HashLink> : <Link to={`/${e.sURL}`}>{e.sNombre}</Link>)}
                        </div>
                    </CollapsibleList>
                    <CollapsibleList sTitulo={LANG.labelSocial}>
                        <div className='footer-link social'>
                            {social.map(s => Boolean(s.bExterno) ? <a href={s.sURL} target='_blank' rel="noreferrer">{s.sNombre}</a> : <Link to={s.sURL}>{s.sNombre}</Link>)}
                        </div>
                    </CollapsibleList>
                    <CollapsibleList sTitulo={LANG.labelLegal}>
                        <div className='footer-link legal'>
                            {legal.map(l => <Link to={l.sURL}>{l.sNombre}</Link>)}
                        </div>
                    </CollapsibleList>
                </div>
            </Grid>
        </Grid>
    )
}

export default FooterHome
